<template>
  <contextual-area
    :open="openContextualArea"
    :items-menu="menuLinks"
    :route-to-close="{name: 'Messages'}"
    @change="menuClicked">
    <v-container
      grid-list-lg
      class="my-0 pb-0">
      <chat-messages
        button-label="Responder"
        empty-class="absolute-center"
        :thread="this.$route.params.messageId"
        placeholder="Envie sua resposta"
        textarea />
    </v-container>
  </contextual-area>
</template>

<script>
  import ContextualArea from '@/components/ui/ContextualArea'
  import ChatMessages from '@/components/ui/ChatMessages/List'
  import { link } from '@/utils/icons'

  export default {
    name: 'ShowMessages',
    components: { ContextualArea, ChatMessages },
    data () {
      return {
        openContextualArea: false,
        menuLinks: [
          {
            label: 'Mensagem',
            to: { name: 'MessageShow' },
            icon: link.messages,
            active: true
          }
        ],
        messages: {}
      }
    },
    mounted () {
      this.openContextualArea = true
    },
    methods: {
      menuClicked (routeName) {
        this.$router.push({ name: routeName })
      }
    }
  }
</script>
