<template>
  <section class="chat-messages">
    <loading
      v-if="loading > 0"
      class="absolute-center" />

    <v-container
      v-else
      class="px-0 py-0">
      <v-layout
        column
        justify-space-between>
        <h2 class="body-1 font-weigth-bold pl-6 pr-2 py-4 d-flex">
          Conversa
          <v-spacer />
          <v-btn
            class="btn-close ml-n2 mr-4"
            data-cy="close"
            icon
            small
            @click="closeModal">
            <v-icon>{{ $root.icons.action.close }}</v-icon>
          </v-btn>
        </h2>
        <perfect-scrollbar :options="options" class="ps-messages">
          <v-list
            ref="list"
            class="pt-0 px-2 transparent chat-scroll">
            <template v-for="[date, msgs] in messages">
              <h2
                :key="date"
                class="body-1 font-weight-bold text-center">
                {{ date }}
              </h2>
              <dynamic-list-components
                v-for="(message) in msgs"
                :ref="`output-${message.id}`"
                :key="`output-${message.id}`"
                :record="message"
                :type="message.type" />
            </template>
          </v-list>
        </perfect-scrollbar>
        <div
          v-if="!disabled"
          class="chat-actions d-flex py-4">
          <wysiwyg
            v-model="newMessage.content"
            class="mr-2"
            placeholder="Envie sua resposta..." />

          <v-btn
            class="mt-0"
            data-cy="submit"
            :loading="isLoading"
            :disabled="!validMessage"
            color="primary"
            @click.prevent="send">
            Enviar
          </v-btn>
        </div>
        <v-alert
          v-else
          :value="true"
          type="info"
          outlined
          class="chat-disabled">
          <p class="darkGray--text ma-0">
            O chat está desativado para esse caso
          </p>
        </v-alert>
      </v-layout>

      <!-- <template v-else>
        <EmptyState class="absolute-center" size="50px" :icon="$root.icons.link.chat">
          {{emptyLabel}}
        </EmptyState>

      </template> -->
    </v-container>
  </section>
</template>


<script>
  import validate from '@/mixins/validate'
  import EmptyState from '@/components/ui/EmptyState'
  import DynamicListComponents from '@/components/ui/ChatMessages/DynamicListComponents'
  import DynamicComponent from '@/components/ui/ChatMessages/DynamicComponent'
  import snackbar from '@/mixins/snackbar'
  import Loading from '@/components/ui/Loading'
  import { mapGetters } from 'vuex'

  export default {
    components: { EmptyState, Loading, DynamicListComponents, DynamicComponent },
    mixins: [ snackbar, validate ],
    props: {
      thread: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: 'Escreva sua mensagem'
      },
      textarea: {
        type: Boolean,
        default: true
      },
      emptyLabel: {
        type: String,
        default: 'No momento não há mensagens aqui'
      },
      emptyIcon: {
        type: String,
        default: ''
      },
      emptyClass: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      options: { suppressScrollX: true, wheelPropagation: false },
      validMessage: false,
      isDisabled: false,
      offsetTop: 0,
      messagesCount: null,
      newMessage: {
        content: ''
      }
    }),
    computed: {
      ...mapGetters({
        userId: 'user/id',
        caseRecord: 'cases/record',
        messages: 'cases/messages',
        loading: 'cases/messagesLoading',
        isLoading: 'cases/loading',
        isNegotiator: 'user/isNegotiator',
        isMediator: 'user/isMediator',
        proposalLastForCase: 'proposal/lastForCase',
        responsibleId: 'currentCompany/responsibleId'
      }),
      hasMessages () {
        return true
      },
      disabledProposalButton () {
        const caseState = this.caseRecord && this.caseRecord.state
        const hasProposal = this.proposalLastForCase && ['visualized', 'created'].includes(this.proposalLastForCase.state)
        return !['accepted', 'refused'].includes(caseState) && hasProposal
      },
      showActionButton () {
        const checkCase = this.$can('CaseProposalCreate')
        const canCreate = this.isNegotiator || this.isMediator
        return checkCase && canCreate
      },
      slug(){
        if(this.isNegotiator){
          return 'NegotiatorAnswer'
        } else if (this.isMediator){
          return 'MediatorAnswer'
        } else {
          return 'GuestAnswer'
        }
      },
      isBot(){
        return this.isNegotiator || this.isMediator
      }
    },
    watch: {
      messages () {
        this.$nextTick(() => {
          this.autoScroll()
        })
      },
      'newMessage.content' (message) {
        this.validMessage = message.length !== 0
      }
    },
    beforeMount () {
      this.$store.dispatch('cases/getMessages', this.$route.params.caseId)
      this.$store.dispatch('proposal/lastForCase', this.$route.params.caseId)
    },
    mounted () {
      document.body.classList.add('has-chat-messages')
      this.items = Object.assign({}, this.messages)
      this.disabled && this.autoScroll()
    },
    destroyed () {
      document.body.classList.remove('has-chat-messages')
    },
    methods: {
      async send () {
        this.$store.commit('cases/loading', true)
        let message = this.newMessage

        const caseId = this.caseRecord.id
        const userId = this.userId
        const userPermissions = this.caseRecord.userPermissions
        let responsibleId = this.responsibleId[caseId]
        if (!responsibleId) {
          const companySnapshot = await this.caseRecord.company
          const companyData = companySnapshot.data()
          const responsibleRef = companyData.responsible
          responsibleId = responsibleRef.id
          this.$store.commit('currentCompany/responsibleId', {caseId: responsibleId})
        }
        const usersUnread = this.caseRecord.userPermissions.filter(id => id !== this.userId && id !== responsibleId)

        const id = this.$uuid.v4()
        const slug = this.slug
        const isBot = this.isBot

        this
          .$store
          .dispatch('cases/sendMessage', { ...message, id, caseId, userId, userPermissions, usersUnread, slug, isBot })
          .then(this._success)
          .catch(this._failure)
      },
      replyEnter (e) {
        !e.shiftKey && this.send()
      },
      _success () {
        this.$store.commit('cases/loading', false)
        this._resetFields()
        this.autoScroll()
      },
      _failure () {
        this.$store.commit('app/setLoading', false)
        this.$nextTick(() => {
          this.snackbar.show = true
          this.snackbar.time = 10000
          this.snackbar.color = 'error'
          this.snackbar.text = 'Houve um problema, tente novamente'
        })
      },
      _resetFields () {
        this.newMessage = {
          content: ''
        }

        this.$refs.form.reset()
      },
      _alertSound () {
        let path = '/notification.mp3'
        let audio = new Audio(path)
        audio.play()
      },
      autoScroll () {
        // const elem = document.querySelector('.ps-messages')

        // if (this.$refs.list && this.$refs.list.$el) {
        //   const height = this.$refs.list.$el.scrollHeight
        //   elem.scrollTop = height
        // }

        var elem = document.querySelector('.ps-messages')
        var chatArea = document.querySelector('.chat-scroll')
        if (chatArea) {
          elem.scrollTop = chatArea.scrollHeight
        }
      },
      setTimeMessage () {
        var hour = new Date().getHours()
        var min = new Date().getMinutes()
        this.newMessage.time = `${hour}:${min}`
      },
      createProposal () {
        this.$emit('createProposal')
      },
      closeModal () {
        this.$router.push({ name: 'Cases' })
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/placeholder'
  @import '../../../assets/style/variables'

  #area-scroll
    position: relative // used only disabled chat

  .chat-messages
    .v-badge--left
      .v-badge__badge
        top: -3px
        left: -24px

    .logo-chat
      width: 40px
      height: 40px

    .v-list-item__content
      overflow: initial

    .v-list-item__subtitle,
    .v-list-item__title
      overflow: initial
      white-space: normal

    .v-list-item__avatar
      height: 100% !important
      align-self: flex-start
      padding: 15px 0

    .data-time
      float: right

    .v-list
      // @extend %scroll
    .ps-messages
      padding: 0 10px
      height: calc(100vh - 146px) !important

    .chat-actions
      padding: 0 20px
      background: #ffffff
      .editr
        border: none
        .editr--toolbar
          display: none

        .editr--content
          font-size: 13px
          padding: 12px 0px
          min-height: auto

          p
            margin: 0
            padding: 0

    .chat-disabled
      margin: 0
      padding: 10px 20px
      border: none!important
      border-top: 1px solid $borderColor!important

      .icon
        margin-left: 20px

  .has-chat-messages

    .v-application .theme--light.v-input-group.v-input-group--textarea:not(.v-input-group--full-width) .v-input-group__input,
    .theme--light .v-input-group.v-input-group--textarea:not(.v-input-group--full-width) .v-input-group__input
      border: 1px solid $borderColor

    #area-scroll
      overflow: hidden // because internal scroll of component
      // height: auto
</style>
