<template>
  <component
    :is="component"
    v-if="component"
    :slug="slug"
    :record.sync="record"
    :type="type"
    :name="type"
    @send="send" />
</template>
<script>
  export default {
    props: {
      slug: String,
      record: Object,
      type: String
    },
    computed: {
      component () {
        let type = this.type
        return () => import(`@/components/ui/ChatMessages/${type}`)
      }
    },
    methods: {
      send (option) {
        this.$emit('send', option)
      }
    }
  }
</script>
